
html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Inconsolata", sans-serif;
  min-height: 100vh;
  color: #DDDEE4;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

#root {
  min-height: 100%;
  position: relative;
}

// MENU
.cross input:checked+svg  {
  .line--1,
  .line--3 {
    stroke-dasharray: 22.627416998 127.64183;
  }

  .line--2 {
    stroke-dasharray: 0 127.64183;
  }
}

.menu {
  .line--1,
  .line--3 {
    stroke-dasharray: 24 126.64183044433594;
  }

  .line--2 {
    stroke-dasharray: 24 70;
  }
  
  input:checked+svg {
    .line--1,
    .line--3 {
      stroke-dashoffset: -94.1149185097;
    }
    .line--2 {
      stroke-dashoffset: -50;
    }
  }

  path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 24 127.64183;
    stroke-dashoffset: -38;
    transition: all .8s cubic-bezier(.645, .045, .355, 1);
  }
}

// BORDER RADIUS
.module {
  position: relative;
}
.module-border {
  position: absolute;
  overflow: visible;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  fill: none;
  stroke: url(#grad);
  stroke-width: 1.6px;
}
#grad stop:nth-child(1) {
  stop-color: #AAB7E5;
}
#grad stop:nth-child(2) {
  stop-color: #8DECE6;
}

/* customize scroll bar */
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
