@font-face {
  font-family: "Inconsolata";
  src: url(../assets/fonts/Inconsolata-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inconsolat";
  src: url(../assets/fonts/Inconsolata-Medium.ttf) format("truetype");
  font-weight: 500;
}


@font-face {
  font-family: "Inconsolata";
  src: url(../assets/fonts/Inconsolata-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inconsolata";
  src: url(../assets/fonts/Inconsolata-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Inconsolata";
  src: url(../assets/fonts/Inconsolata-ExtraBold.ttf) format("truetype");
  font-weight: 900;
}

