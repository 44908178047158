.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  background: transparent;
  border: 1px solid #8692A6;
  box-sizing: border-box;
  border-radius: 4px;
  outline: 0;
  border-radius: 4px;
  font-family: "Inconsolata", sans-serif;
}

.InputContainer {
  input::placeholder {
    color: #A9B7C4;
    font-family: "Inconsolata", sans-serif;
  }
}

input:focus,
.StripeElement--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}